<template>
  <v-container style="max-width: 800px">
    <v-card class="mt-16">
      <v-card-title>
        {{ $t("notAuthorized") }}
      </v-card-title>
      <v-card-text>
        <v-alert type="warning">
          {{ $t("youAreNotAuthPage") }}
        </v-alert>
        <br />
        <div v-if="retryPageIsSet" class="text-center">
          <v-row>
            <v-col>
              {{ $t("noAuthTryAgainInfo") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <LoadingSpinner v-if="loading" :smart-delay="false" />
              <v-btn v-else @click="tryAgain" data-testid="btnNotAuthorized">
                {{ $t("tryAgain") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import store from "@/store";
import router from "@/router";
import LoadingSpinner from "@/components/misc/LoadingSpinner";

export default {
  name: "NotAuthorized",
  components: { LoadingSpinner },

  computed: {
    retryPageIsSet() {
      return store.state.retryPageOnNoPermission;
    }
  },

  data: () => ({
    loading: false
  }),

  methods: {
    tryAgain() {
      console.log("nav to " + store.state.retryPageOnNoPermission);
      // Indicate "working" mode. Beacuse if user still dosen't have permission
      // to target site, he/she will return here instantly. This will happen
      // so fast that the user may not notice the new navigation was attempted
      this.loading = true;
      setTimeout(() => {
        // Re-fetch latest user profile from server
        store
          .dispatch("updateUserProfile", true)
          .then(() => {
            router.push(store.state.retryPageOnNoPermission);
          })
          .finally(() => {
            this.loading = false;
          });
      }, 500);
    }
  }
};
</script>
